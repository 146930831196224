<template>
  <div class="about text-center">
    <div class="container pt-5 pb-5">
      <h2 class="font-weight-bold mb-4">Background</h2>

      <p>
        I graduated from Drury University in 2012 with a major in Computer Information Systems.
        Although I come from a family of developers, I didn't become interested in game design and
        programming until my freshman year of college, when I tried my hand at designing my own
        computer game and absolutely fell in love. It was so exciting to build the logic piece by piece,
        find solutions to the various problems I came across, and watch my very own creation come to life.
        I've learned a great deal from designing my own games, including how to research the market,
        collaborate with a team, and design a user-friendly interface, and of course I've also benefited
        from the hands-on programming experience.
      </p>

      <p>
        In 2016, I graduated from Centriq Training where I trained to become a .NET developer. Since then,
        I've been working as a software engineer focusing on developing and maintaining complex web applications.
        I'm currently a Principal Engineer/Architect at MSTS, which involves working on independent and
        team-based projects to support internal business users as well as external clients and their users.
      </p>

      <p>
        Looking to get back to my roots in game design, I've been taking classes at CG Spectrum, where I'm
        learning the fundamentals of game design from industry professionals and getting practical experience
        designing and prototyping my own games.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutSection',
};
</script>

<style lang="scss" scoped>

</style>
