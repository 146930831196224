<template>
  <div class="details text-center">
    <div class="container pt-5 pb-5">
      <div class="position-relative">
        <b-button class="position-absolute top-0 right-0 text-white" variant="info"
          href="/downloads/CeraWhiteResume.pdf" target="_blank">
          Download PDF
        </b-button>
        <h2 class="font-weight-bold mb-4">CERA A. WHITE</h2>
      </div>

      <hr class="mt-5 mb-5" />

      <div class="text-left">
        <h3 class="mb-4">SKILLS</h3>
        <ul>
          <li>Technical experience with Visual Studio, C#, Unity, and developing web applications and game prototypes.</li>
          <li>Careful attention to detail, with the ability to troubleshoot and formulate solutions to problems as they arise.</li>
          <li>Skilled at understanding user needs and finding solutions through research, creativity, and independent thinking.</li>
          <li>Eager for new knowledge and experiences, with the ability to learn and adapt to new skills quickly.</li>
        </ul>

        <hr class="mt-5 mb-5" />

        <h3 class="mb-4">EDUCATION</h3>

        <h4>Introduction to Game Design</h4>
        <p>CG Spectrum</p>
        <ul>
          <li>
            Game Design Basics: systems and mechanics, how to deconstruct games to better understand how they were designed,
            and how to design and prototype my own games.
          </li>
          <li>Expected graduation February 6, 2021.</li>
        </ul>

        <h4>Application Development</h4>
        <p>Centriq Training</p>
        <ul>
          <li>
            Technical Training: Visual Studio, C# .NET, ASP.NET, MVC, SQL, SQL Server, HTML 5, CSS3, JavaScript, jQuery, RESTful API,
            Entity Framework, Responsive/Mobile Development, Professional IT Project Experience.
          </li>
          <li>Graduated June 16, 2016.</li>
        </ul>

        <h4>Bachelor of Business Administration</h4>
        <p>Drury University</p>
        <ul>
          <li>Major in Computer Information Systems, Minor in Global Studies.</li>
          <li>Graduated with Honors, GPA: 3.91.</li>
          <li>Honor Society, Alpha Lambda Delta, Member.</li>
          <li>Graduated December 12, 2012.</li>
        </ul>

        <hr class="mt-5 mb-5" />

        <h3 class="mb-4">WORK EXPERIENCE</h3>

        <h4>Principal Engineer/Architect I</h4>
        <p>MSTS (Multi Service Technology Solutions)</p>
        <ul>
          <li>
            Maintain and modify legacy applications (Perl, Pro*C) according to internal and external client requests, and support on-call and ad hoc reporting efforts.
          </li>
          <li>
            Design and build new client-facing features using a Rails API and Vue.js front-end.
          </li>
          <li>
            Pioneered front-end development with Vue.js and Quasar Framework, helped develop enterprise-wide style guide, and built a shared component library to make
            front-end development more efficient across teams and products.
          </li>
          <li>
            Manage bi-weekly workshops for software and testing automation engineers to practice problem-solving skills and discuss relevant topics to the technology industry,
            learning to be more effective developers both within and outside the company.
          </li>
        </ul>

        <h4>Software Engineer</h4>
        <p>UnitedLex Corporation</p>
        <ul>
          <li>
            Designed and developed high-quality software applications by writing testable, efficient, well-documented code.
          </li>
          <li>
            Supported and worked with business users and product management to understand, analyze, and develop solutions to solve client-driven requirements.
          </li>
          <li>
            Utilized knowledge of a full range of technologies to implement intelligent UI/UX design, added functionality through a robust service-based
            architecture, and supported efficient database operations.
          </li>
        </ul>

        <h4>Web Designer &amp; Administrator</h4>
        <p>White Sun Crafts</p>
        <ul>
          <li>
            Designed and administered a <a href="http://whitesuncrafts.anigrams.org" target="_blank">complete data-driven e-commerce site</a> from scratch using
            ASP.NET, which is integrated with PayPal in order to securely process online credit card transactions.
          </li>
          <li>
            Maintained and improved the site based on feedback and requirements from the client, which were logged through a <a href="http://support.anigrams.org"
            target="_blank">custom-built support ticket tracking application</a> where the client could submit issues and suggestions.
          </li>
        </ul>

        <h4>Training Supervisor &amp; Inventory Specialist</h4>
        <p>RGIS Inventory Specialists</p>
        <ul>
          <li>
            Coordinated with a team of 6 to perform annual inventory audits for all Walgreens within the Greater Kansas City area, including regular travel
            to such cities as Topeka, KS and Columbia, MO.
          </li>
          <li>
            Counted physical inventory in high-dollar areas such as pharmacies and stockrooms accurately and efficiently using the latest scanning equipment and software.
          </li>
          <li>
            Trained new hires and employees in the proper use of scanning equipment and efficient counting techniques.
          </li>
        </ul>

        <h4>Web Developer &amp; Office Intern</h4>
        <p>Meyer Accounting &amp; Tax, LLC</p>
        <ul>
          <li>
            Created and maintained a database of additional client information to function alongside commercial tax preparation software, intended to allow office
            employees to access relevant information on a client more easily and quickly.
          </li>
          <li>
            Designed and implemented a new company website, including an accompanying mobile version, according to requirements set by the employer.
          </li>
          <li>
            Organized, filed, and created digital copies of client files while assisting with various office duties.
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'ResumeView',
  components: {
  },
};
</script>
